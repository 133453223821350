@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
 a {
  @apply text-caribbean hover:text-caribbean-700
 }
}

:root {
  --primary-color: #0BC9CD;
  --primary-color-rgb: 11, 201, 205;
  --secondary-color: #08605F;
  --highlight-color: #D95D39;
  --background-color: #0E1428;
  --light-color: #F9DEC9;
}

/* a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: var(--secondary-color);
} */

.eef-card {
  background-color: rgba(var(--primary-color-rgb),0.2);
  color: var(--light-color);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  backdrop-filter: blur(12px);
}

.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 0 10px 0 rgba(var(--primary-color-rgb),0.5);
  transform: translateY(-5px);
}